import Vue from 'vue';
import { AddressEdit } from 'vant';
import { Toast } from 'vant';
import { Switch } from 'vant';
import { Area } from 'vant';
import { Popup } from 'vant';
import area from '../../utils/area.js'
import { mapState, mapActions } from 'vuex';
import  role from '../../assets/lib/role';
import { Dialog } from 'vant';

Vue.use(Dialog);
Vue.use(Popup);
Vue.use(Area);
Vue.use(Switch);
Vue.use(AddressEdit);
export default {
  components: {},
  data() {
    return {
      disable:false,
      show: false,
      // 智能填写
      show1:false,
      checked: true,
       areaList:area,
       areas:'',
       addressId:'',
      //  内容
      addressName:"",
      addressPhone:"",
      detailedAddress:"",
      Province:"",
      City:"",
      Area:"",
      theArea:'',
      areaCode:'',
      formerData:{}
    };
  },
  beforeRouteLeave (to, from, next) {
    // 导航离开该组件的对应路由时调用
    // 可以访问组件实例 `this`
    if(this.formerData.shoppingAddressUsername==this.addressName&&
      this.formerData.shoppingAddressUserPhone==this.addressPhone&&
      this.formerData.shoppingAddressDetail==this.detailedAddress&&
      this.formerData.shoppingAddressArea==this.areas&&
      this.formerData.checked==this.checked){
        next()
      }else{
        setTimeout(()=>{
          Dialog.confirm({
            confirmButtonText:'保存',
            cancelButtonText:'不保存',
            message: '是否保存本次编辑结果？',
          })
            .then(() => {
              // on confirm
              var flags=this.screen();
              if(flags){
                let result = 0;
                if(this.checked){
                  result=1;
                }else{
                  result=0;
                }
                var data=JSON.stringify({
                  shoppingAddressId:this.$route.query.id,
                  shoppingAddressUsername:this.addressName,
                  shoppingAddressUserPhone:this.addressPhone,
                  shoppingAddressDetail:this.detailedAddress,
                  shoppingAddressArea:this.areas,
                  shoppingAddressState:result
                })
                this.editAddress({thedata:data}).then(res=>{
                  if(res.code==200){
                   next()
                  }
                })
              }else{
                next(false)
              }

            })
            .catch(() => {
              // on cancel
              next()
            });
        },200)
      }




  },
  watch: {
    $route() {
      if(this.$route.query.id!=undefined){
        this.getDetail();
      }

    },

    },

  created() {
    this.getDetail();
  },
  updated() {
    if(this.addressName==''||this.areas=='' ||this.addressPhone==''||this.detailedAddress==''){
      this.disable=true
    }else{
      this.disable=false
    }

  },
  methods: {
    ...mapActions('address', ['defaultAddress', 'delAddress', 'addAddress', 'editAddress', 'findAddress', 'addressDetail','editDefaultAddress']),
    back() {
      this.$router.go(-1);
    },
    getDetail(){
      var query=this.$route.query.id;
      this.addressDetail(query).then(res=>{
        if(res.code==200){
        this.addressName=res.data.shoppingAddressUsername;
        this.addressPhone=res.data.shoppingAddressUserPhone;
        this.detailedAddress=res.data.shoppingAddressDetail;
        // this.Area=res.data.shoppingAddressArea;
        this.areas=res.data.shoppingAddressArea;
        this.result=res.data.shoppingAddressState;
        this.formerData=res.data;

        if(this.result==1){
          this.checked=true;
        }else{
          this.checked=false;
        }
        this.formerData.checked=this.checked
        }
      })
    },
    toedit(){
      this.$router.push({ path: '/addressList',query:{back:this.$route.query.back,payType:this.$route.query.payType} });
    },
    cancel(){
      this.show=!this.show;
    },
    sure(ss){

      this.areas=''
      for(let i=0;i<ss.length;i++){
        this.areas+=ss[i].name
      }
      this.show=!this.show;

    },
    showPopup() {
      this.show = true;
    },
    open(){
    this.show1 = !this.show1;
    },
    // 郑州校验role
    screen(){
      if(this.addressName.length<2||this.addressName.length>30){
        Toast("请输入2-30位的收货人姓名")
        return false
      }else if(!role.quphone.test(this.addressPhone)){
        Toast("请输入正确格式的联系电话")
        return false
      }else if(this.areas==''){
        Toast("请选择所在地区")
        return false

      }else if(this.detailedAddress.length<2||this.detailedAddress.length>255){
        Toast("请输入2-255位的收货地址")
        return false
      }else{
        return true
      }


    }
  },

  }
